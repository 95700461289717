import { FormEvent, useEffect, useState } from 'react';

import { Steps } from '@/components/forms/ProgressBarIndicator/types';
import LanguagesList from '@/components/LanguageSelector/data';

import { useCountries, useTranslations } from '@/hooks';
import { Lang } from '@/i18n/types';
import { register } from '@/services';
import { registration as registrationStore } from '@/store';

import { SKYMINDER_API } from '@/constants';
import {
  encodeURI,
  getCookie,
  getUserCountry,
  invalidateCookie,
  setCookieMinutes,
} from '@/utils';
import { getAndReplaceUrlQuery, getPreferredLanguage } from '@/utils/browser';
import { useStore } from '@nanostores/react';
import axios from 'axios';
import { defaultFieldsValues } from './data';
import {
  Company,
  Country,
  Errors,
  FieldKey,
  Fields,
  SexOptions,
  UseRegistrationProps,
} from './types';
import zodRegistration from './zod';

const useRegistration = ({
  lang,
  currentStep: cStep = 0,
}: UseRegistrationProps) => {
  const {
    components: {
      forms: {
        female,
        male,
        companyStep,
        personalStep,
        emailStep,
        searchAndConfirm,
        required,
        companyNotFound,
      },
    },
    pages: {
      register: { unableToCompleteRegistration },
    },
  } = useTranslations(lang);
  const { countries } = useCountries(lang);
  const {
    RegisterDesktopCompanyStep,
    RegisterDesktopEmailStep,
    RegisterDesktopPersonalStep,
    RegisterMobileStep,
  } = zodRegistration(lang);
  const registration = useStore(registrationStore.$registration);

  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState<Fields>(defaultFieldsValues);
  const [currentStep, setCurrentStep] = useState(cStep ?? 0);
  const [steps, setSteps] = useState<Steps>([
    {
      label: companyStep,
    },
    {
      label: personalStep,
    },
    {
      label: emailStep,
    },
  ]);
  const [errors, setErrors] = useState<Errors>([]);

  const languagesOptions = LanguagesList;

  const sexOptions: SexOptions = [
    {
      value: 'female',
      label: female,
    },
    {
      value: 'male',
      label: male,
    },
  ];

  const [country, setCountry] = useState<Country>();
  const [hasSelected, setHasSelected] = useState(false);
  const [company, setCompany] = useState<Company>({
    value: {
      houseNumber: '',
      name: '',
      street: '',
      supplierId: '',
      zipCode: '',
      city: '',
    },
  });

  const setFieldsValues = ({
    companyName,
    country,
    language,
    sex,
    firstName,
    lastName,
    phoneCountryCode,
    phoneNumber,
    confirmEmail,
    dataPrivacyTreatment,
    email,
    termsAndConditions,
  }: Fields) => {
    const localFields = fields;

    const localCompanyName = companyName ?? localFields.companyName;
    const localCountry = country ?? localFields.country;
    const localLanguage = language ?? localFields.language;

    const localSex = sex ?? localFields.sex;
    const localFirstName = firstName ?? localFields.firstName;
    const localLastName = lastName ?? localFields.lastName;
    const localPhoneCountryCode =
      phoneCountryCode ?? localFields.phoneCountryCode;
    const localPhoneNumber = phoneNumber ?? localFields.phoneNumber;

    const localEmail = email ?? localFields.email;
    const localDataPrivacyTreatment =
      dataPrivacyTreatment ?? localFields.dataPrivacyTreatment;
    const localConfirmEmail = confirmEmail ?? localFields.confirmEmail;
    const localTermsAndConditions =
      termsAndConditions ?? localFields.termsAndConditions;

    setFields({
      companyName: localCompanyName,
      country: localCountry,
      language: localLanguage,
      sex: localSex,
      firstName: localFirstName,
      lastName: localLastName,
      phoneCountryCode: localPhoneCountryCode,
      phoneNumber: localPhoneNumber,
      email: localEmail,
      confirmEmail: localConfirmEmail,
      dataPrivacyTreatment: localDataPrivacyTreatment,
      termsAndConditions: localTermsAndConditions,
    });
  };

  const handleCountry = (value: string) => {
    setCountry({ value });
  };

  const handleCompany = (companyParam: Company) => {
    setCompany(companyParam);
  };

  const handleCompanyNotFound = (value: boolean) => {
    setHasSelected(value);
  };

  const handleInput = (e: any, fieldKey: FieldKey, errorMessage?: string) => {
    const prevValue = fields[fieldKey]?.value ?? '';
    const value = {
      value:
        typeof e?.currentTarget?.value !== 'undefined'
          ? e?.currentTarget?.value
          : prevValue,
      invalidText: errorMessage,
    };
    const field = {
      [fieldKey]: value,
    };

    setFieldsValues(field);
  };

  const handleValueInput = (
    stringValue: string,
    fieldKey: FieldKey,
    errorMessage?: string,
  ) => {
    const value = {
      value: stringValue,
      invalidText: errorMessage,
    };
    const field = {
      [fieldKey]: value,
    };

    setFieldsValues(field);
  };

  const handleErrorInput = (errorMessage: string, fieldKey: FieldKey) => {
    const localFields = fields;

    const value = {
      value: localFields[fieldKey]?.value,
      invalidText: errorMessage,
    };
    const field = {
      [fieldKey]: value,
    };

    setFieldsValues(field);
  };

  const handleErrorCompanyNameInput = (
    errorMessage: string,
    fieldKey: FieldKey,
  ) => {
    const localFields = fields;

    const value = {
      value: localFields[fieldKey]?.value,
      invalidText: errorMessage,
    };
    const field = {
      [fieldKey]: value,
    };

    const company = {
      value: fields.companyName?.value,
      invalidText: errorMessage,
    };

    setFieldsValues({ [fieldKey]: company });
  };

  const setCompanyNameHasSelected = (value: boolean) => {
    setFieldsValues({
      companyName: {
        value: fields.companyName!.value,
        invalidText: fields.companyName?.invalidText,
        hasSelected: value,
      },
    });
  };

  const handleManualValueInput = (
    stringValue: string,
    fieldKey: FieldKey,
    errorMessage?: string,
  ) => {
    const value = {
      value: stringValue,
      invalidText: errorMessage ?? undefined,
    };

    const field = {
      [fieldKey]: value,
    };

    setFieldsValues(field);
  };

  const resetCompanyNameOnCountrySelection = () => {
    setFieldsValues({
      companyName: {
        value: '',
        invalidText: searchAndConfirm,
      },
    });
  };

  const handleDropdown = (key: string, value: any) => {
    setFieldsValues({
      [key]: {
        value: value.value,
      },
    });
  };

  const handleRadioButton = (key: string, value: any) => {
    setFieldsValues({
      [key]: {
        value: value,
      },
    });
  };

  const handleCheckbox = (key: string) => {
    // @ts-ignore
    const prev = fields[key];
    setFieldsValues({
      [key]: {
        value: !prev.value,
      },
    });
  };

  const clearProgressIndicatorErrors = () => {
    setSteps((prev) => {
      return prev.map((value) => ({
        label: value.label,
        invalid: false,
      }));
    });
  };

  const handleNextStep = () => {
    registrationStore.nextStep();
    setCurrentStep((prev) => prev + 1);
  };

  const handlePreviousStep = () => {
    registrationStore.previousStep();
    setCurrentStep((prev) => prev - 1);
  };

  const parseErrors = () => {
    switch (currentStep) {
      case 0: {
        return RegisterDesktopCompanyStep.safeParse(fields);
      }
      case 1: {
        return RegisterDesktopPersonalStep.safeParse(fields);
      }
      case 2: {
        return RegisterDesktopEmailStep.safeParse(fields);
      }
      default: {
        return RegisterMobileStep.safeParse(fields);
      }
    }
  };

  const handleSetErrors = (errorObjects: any) => {
    if (Object.keys(errorObjects).length > 0) {
      setSteps((prev) => {
        const prevValues = prev;
        prevValues[currentStep].invalid = true;

        return prevValues;
      });
    }
    setFieldsValues({
      ...errorObjects,
    });
  };

  const handleSetMobileErrors = (errorObjects: any) => {
    setFieldsValues({
      ...errorObjects,
    });
  };

  const handleErrors = () => {
    clearProgressIndicatorErrors();
    const parsed = parseErrors();

    if (company.value.name.length === 0) {
      // @ts-ignore
      setFields((prev) => {
        return {
          ...prev,
          companyName: {
            value: prev.companyName?.value,
            hasSelected: prev.companyName?.hasSelected,
            invalidText: required,
          },
        };
      });
    } else {
      // @ts-ignore
      setFields((prev) => {
        return {
          ...prev,
          companyName: {
            value: prev.companyName?.value,
            hasSelected: prev.companyName?.hasSelected,
            invalidText: undefined,
          },
        };
      });
    }

    if (parsed.success && company.value.name) return true;
    // @ts-ignore
    const errors = parsed.error.issues;
    const errorObjects = {} as any;
    // @ts-ignore
    errors.forEach((error) => {
      const path = error.path[0] as FieldKey;
      const message = error.message;
      const invalidTextObject = {
        invalidText: message,
      };

      errorObjects[path] = invalidTextObject;
    });

    if (currentStep === 4) {
      handleSetMobileErrors(errorObjects);
    } else {
      handleSetErrors(errorObjects);
    }

    return false;
  };

  const handleStep = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errorHandling = handleErrors();

    if (errorHandling) {
      if (currentStep < 2) {
        handleNextStep();
      }
      return true;
    }

    return false;
  };

  const convertObject = () => {
    const object: { [key: string]: any } = {};

    const mappedFields = Object.entries(fields).map((field) => ({
      [field[0]]: field[1].value,
    }));

    mappedFields.forEach((field) => {
      const newObject = Object.entries(field);
      // @ts-ignore
      object[newObject[0][0]] = newObject[0][1];
    });

    return object;
  };

  const removeCompanyNotFoundLabel = (companyName: string) => {
    const split = companyName.split('(' + companyNotFound + ')');
    return split[0].trim();
  };

  const handleWholeDesktopForm = async (e: FormEvent<HTMLFormElement>) => {
    const stepHandled = handleStep(e);

    if (stepHandled) {
      setIsLoading(true);
      const converted = convertObject();

      const companyNameArray = converted.companyName.split('\u2800');

      const identifiers = {
        ...(companyNameArray.length === 4
          ? { supplierId: companyNameArray[3], vatNumber: companyNameArray[2] }
          : {}),
        ...(companyNameArray.length === 3
          ? { supplierId: companyNameArray[2] }
          : {}),
      };

      const trimmed = removeCompanyNotFoundLabel(companyNameArray[0]);

      converted.companyName = {
        name: trimmed,
        address: companyNameArray[1],
        ...identifiers,
      };

      converted.company = company.value;
      converted.company.name = trimmed;
      converted.country = country?.value;
      converted.phone = converted.phoneCountryCode + converted.phoneNumber;
      const referral = getCookie('referral');

      try {
        const response = await register.submitRegistration({
          ...converted,
          ...(referral && { referral }),
        });

        invalidateCookie('referral');

        if (response.data.ok) {
          registrationStore.setValue({
            hasRegistrationSucceeded: true,
            email: fields.email?.value,
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setErrors([unableToCompleteRegistration]);
        }
      } catch (error) {
        setIsLoading(false);
        setErrors([unableToCompleteRegistration]);
      }
    }
  };

  const handleMobileForm = async (e: FormEvent<HTMLFormElement>) => {
    setErrors([]);
    const stepHandled = handleStep(e);

    if (stepHandled) {
      setIsLoading(true);
      const converted = convertObject();

      const companyNameArray = converted.companyName.split('\u2800');

      const identifiers = {
        ...(companyNameArray.length === 4
          ? { supplierId: companyNameArray[3], vatNumber: companyNameArray[2] }
          : {}),
        ...(companyNameArray.length === 3
          ? { supplierId: companyNameArray[2] }
          : {}),
      };

      const trimmed = removeCompanyNotFoundLabel(company.value.name);

      converted.companyName = {
        name: companyNameArray[0],
        address: companyNameArray[1],
        ...identifiers,
      };
      converted.company = company.value;
      converted.company.name = trimmed;
      converted.phone = converted.phoneCountryCode + converted.phoneNumber;
      const referral = getCookie('referral');

      try {
        const response = await register.submitRegistration({
          ...converted,
          ...(referral !== undefined ? { referral } : undefined),
        });

        invalidateCookie('referral');

        if (response.data.ok) {
          registrationStore.setValue({
            hasRegistrationSucceeded: true,
            email: fields.email?.value,
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setErrors([unableToCompleteRegistration]);
        }
      } catch (error) {
        setIsLoading(false);
        setErrors([unableToCompleteRegistration]);
      }
    }
  };

  const preselectCountry = (lang: string) => {
    const userCountry = getUserCountry();
    const filteredCountry = countries.filter(
      (country) => userCountry === country.filterLabel,
    );

    if (filteredCountry.length > 0) {
      setFieldsValues({
        language: {
          value: lang,
        },
        country: {
          value: filteredCountry[0].value,
        },
        phoneCountryCode: {
          value: filteredCountry[0].phoneCountryCode,
        },
      });
    }
  };

  const preselectPhoneCountryCode = () => {
    const filteredPhoneCountryCode = countries.filter((country) => {
      return country.value === fields.country?.value;
    });

    setFieldsValues({
      phoneCountryCode: {
        value: filteredPhoneCountryCode[0].phoneCountryCode,
      },
    });
  };

  // const getCompanyList = async (companyName: string): Promise<any> => {
  //   const iso3166 = countries.filter(
  //     (countryItem) => countryItem.value === country?.value,
  //   );

  //   try {
  //     const respose = await axios.get(
  //       'https://corsproxy.io/?' +
  //         encodeURIComponent(
  //           SKYMINDER_API +
  //             `/companies?address.countryCode=${iso3166[0].phoneCountryCodeISO3166}&name=${companyName}`,
  //         ),
  //       {
  //         headers: {
  //           Accept: 'application/vnd.skyminder.api.v1+json',
  //         },
  //         auth: {
  //           username: SKYMINDER_API_BASIC_AUTH_USERNAME,
  //           password: SKYMINDER_API_BASIC_AUTH_PASSWORD,
  //         },
  //       },
  //     );

  //     if (respose.data?.companies && respose.data?.companies.length > 0) {
  //       return respose.data.companies;
  //     } else {
  //       return [];
  //     }
  //   } catch (error) {
  //     return [];
  //   }
  // };

  useEffect(() => {
    const preferredLanguage = getPreferredLanguage();
    preselectCountry(preferredLanguage as Lang);
  }, []);

  useEffect(() => {
    const queryStrings = getAndReplaceUrlQuery();

    queryStrings.forEach((query) => {
      if (query.key === 'referral') {
        setCookieMinutes(query.key, encodeURI(query.value), 60);
      }
    });
  }, []);

  return {
    fields,
    countries,
    sexOptions,
    languagesOptions,
    steps,
    currentStep,
    registration,
    errors,
    hasSelected,
    isLoading,
    company,
    setFieldsValues,
    setStoreValue: registrationStore.setValue,
    handleInput,
    handleDropdown,
    handleRadioButton,
    handleCheckbox,
    handleNextStep,
    handlePreviousStep,
    handleStep,
    handleWholeDesktopForm,
    handleMobileForm,
    handleValueInput,
    // getCompanyList,
    handleManualValueInput,
    resetCompanyNameOnCountrySelection,
    handleErrorInput,
    handleErrorCompanyNameInput,
    setCompanyNameHasSelected,
    handleCountry,
    handleCompanyNotFound,
    handleCompany,
  };
};

export default useRegistration;
