import { ZodIssueCode, z } from 'zod';

import { Lang } from '@/i18n/types';
import useTranslations from '../useTranslations';

const phoneRegex = /^\d+$/;

const zodRegistration = (lang: Lang) => {
  const {
    components: {
      forms: {
        mustNotBeEmpty,
        emailsMustMatch,
        mustBeSelected,
        required,
        atLeast4Chars,
        atMost16Chars,
        onlyNumber,
        selectionRequired,
        invalidEmail,
      },
    },
  } = useTranslations(lang);

  const RegisterDesktopCompanyStep = z.object({
    company: z.object({
      invalidText: z.optional(z.string()),
      selected: z
        .boolean({ required_error: selectionRequired })
        .refine((value) => {
          return value === true;
        }),
      value: z.object({
        city: z.optional(z.string({ required_error: required })),
        houseNumber: z.optional(z.string({ required_error: required })),
        name: z
          .string({ required_error: required })
          .min(1, { message: mustNotBeEmpty }),
        street: z.optional(z.string({ required_error: required })),
        supplierId: z.optional(z.string({ required_error: required })),
        vatNumber: z.optional(z.string({ required_error: required })),
        providerName: z.optional(z.string({ required_error: required })),
        supplierIdType: z.optional(z.string({ required_error: required })),
        zipCode: z.optional(z.string({ required_error: required })),
      }),
    }),
    country: z.object({
      invalidText: z.optional(z.string()),
      value: z.object({
        label: z
          .string({ required_error: required })
          .min(1, { message: mustNotBeEmpty }),
        value: z
          .string({ required_error: required })
          .min(1, { message: mustNotBeEmpty }),
      }),
    }),
    language: z.object({
      invalidText: z.optional(z.string()),
      value: z.object({
        label: z
          .string({ required_error: required })
          .min(1, { message: mustNotBeEmpty }),
        value: z
          .string({ required_error: required })
          .min(1, { message: mustNotBeEmpty }),
      }),
    }),
  });

  const RegisterDesktopPersonalStep = z.object({
    sex: z.object({
      invalidText: z.optional(z.string()),
      value: z
        .string({ required_error: required })
        .min(1, { message: mustBeSelected }),
    }),
    firstName: z.object({
      invalidText: z.optional(z.string()),
      value: z
        .string({ required_error: required })
        .min(1, { message: mustNotBeEmpty }),
    }),
    lastName: z.object({
      invalidText: z.optional(z.string()),
      value: z
        .string({ required_error: required })
        .min(1, { message: mustNotBeEmpty }),
    }),
    phoneNumber: z.object({
      invalidText: z.optional(z.string()),
      value: z
        .string({ required_error: required })
        .min(4, { message: atLeast4Chars })
        .max(16, { message: atMost16Chars })
        .trim()
        .refine((value) => phoneRegex.test(value), onlyNumber),
    }),
  });

  const RegisterDesktopEmailStep = z
    .object({
      email: z.object({
        invalidText: z.optional(z.string()),
        value: z
          .string({ required_error: required })
          .min(1, { message: mustNotBeEmpty })
          .email(),
      }),
      confirmEmail: z.object({
        invalidText: z.optional(z.string()),
        value: z
          .string({ required_error: required })
          .min(1, { message: mustNotBeEmpty })
          .email(),
      }),
      termsAndConditions: z.object({
        invalidText: z.optional(z.string()),
        value: z
          .boolean({ required_error: mustNotBeEmpty })
          .refine((value) => value === true, {
            message: mustBeSelected,
          }),
      }),
      dataPrivacyTreatment: z.object({
        invalidText: z.optional(z.string()),
        value: z
          .boolean({ required_error: mustNotBeEmpty })
          .refine((value) => value === true, {
            message: mustBeSelected,
          }),
      }),
    })
    .superRefine(({ email, confirmEmail }, ctx) => {
      if (email.value !== confirmEmail.value) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: emailsMustMatch,
          path: ['confirmEmail'],
        });
      }
    });

  const RegisterMobileStep = z
    .object({
      company: z.object({
        invalidText: z.optional(z.string()),
        selected: z
          .boolean({ required_error: selectionRequired })
          .refine((value) => {
            return value === true;
          }),
        value: z.object({
          city: z.optional(z.string({ required_error: required })),
          houseNumber: z.optional(z.string({ required_error: required })),
          name: z
            .string({ required_error: required })
            .min(1, { message: mustNotBeEmpty }),
          street: z.optional(z.string({ required_error: required })),
          supplierId: z.optional(z.string({ required_error: required })),
          supplierIdType: z.optional(z.string({ required_error: required })),
          vatNumber: z.optional(z.string({ required_error: required })),
          zipCode: z.optional(z.string({ required_error: required })),
        }),
      }),
      country: z.object({
        invalidText: z.optional(z.string()),
        value: z.object({
          label: z
            .string({ required_error: required })
            .min(1, { message: mustNotBeEmpty }),
          value: z
            .string({ required_error: required })
            .min(1, { message: mustNotBeEmpty }),
        }),
      }),
      language: z.object({
        invalidText: z.optional(z.string()),
        value: z.object({
          label: z
            .string({ required_error: required })
            .min(1, { message: mustNotBeEmpty }),
          value: z
            .string({ required_error: required })
            .min(1, { message: mustNotBeEmpty }),
        }),
      }),
      sex: z.object({
        invalidText: z.optional(z.string()),
        value: z
          .string({ required_error: required })
          .min(1, { message: mustNotBeEmpty }),
      }),
      firstName: z.object({
        invalidText: z.optional(z.string()),
        value: z
          .string({ required_error: required })
          .min(1, { message: mustNotBeEmpty }),
      }),
      lastName: z.object({
        invalidText: z.optional(z.string()),
        value: z
          .string({ required_error: required })
          .min(1, { message: mustNotBeEmpty }),
      }),
      phoneNumber: z.object({
        invalidText: z.optional(z.string()),
        value: z
          .string({ required_error: required })
          .min(4, { message: atLeast4Chars })
          .max(16, { message: atMost16Chars })
          .trim()
          .refine((value) => phoneRegex.test(value), onlyNumber),
      }),
      email: z.object({
        invalidText: z.optional(z.string()),
        value: z
          .string({ required_error: required })
          .min(1, { message: mustNotBeEmpty })
          .email({ message: invalidEmail }),
      }),
      confirmEmail: z.object({
        invalidText: z.optional(z.string()),
        value: z
          .string({ required_error: required })
          .min(1, { message: mustNotBeEmpty })
          .email({ message: invalidEmail }),
      }),
      termsAndConditions: z.object({
        invalidText: z.optional(z.string()),
        value: z
          .boolean({ required_error: mustNotBeEmpty })
          .refine((value) => value === true, {
            message: mustBeSelected,
          }),
      }),
      dataPrivacyTreatment: z.object({
        invalidText: z.optional(z.string()),
        value: z
          .boolean({ required_error: mustNotBeEmpty })
          .refine((value) => value === true, {
            message: mustBeSelected,
          }),
      }),
    })
    .superRefine(({ email, confirmEmail }, ctx) => {
      if (email.value !== confirmEmail.value) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: emailsMustMatch,
          path: ['confirmEmail'],
        });
      }
    });

  return {
    RegisterDesktopCompanyStep,
    RegisterDesktopPersonalStep,
    RegisterDesktopEmailStep,
    RegisterMobileStep,
  };
};

export default zodRegistration;
