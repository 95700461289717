import { TextInput } from '@carbon/react';
import '@carbon/react/scss/components/text-input/_index.scss';
import React, {
  ChangeEvent,
  FC,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';

import { CustomColorLoadSpinner } from '@/components/forms';
import { useOutsideClick, useTranslations } from '@/hooks';
import { ListItemButton } from '../SearchableDropdown/types';
import styles from './index.css';
import type { FetchedCompany, InputProps, SelectedCompany } from './types';

const CompanyNameInput: FC<InputProps> = ({
  register,
  name,
  dataTestId,
  sideButtonDataTestId,
  id,
  required,
  disabled,
  label,
  type,
  sideButton,
  invalid,
  invalidText,
  margin,
  padding,
  value,
  tabOrder,
  isDisabled = false,
  onChange,
  APICall,
  handleProgressIndicator,
  onSelection,
  lang,
  inputRef,
  toggleDisabledAllFields,
  timeout,
  ...rest
}) => {
  const {
    components: {
      forms: {
        enterCompanyNameToSearch,
        companyNotFound,
        searchAndSelectAgain,
        companyAddress,
        search,
        supplierId,
        vatNumber,
        loading,
        timeout: timeoutTranslation,
      },
    },
  } = useTranslations(lang);

  const [isTyping, setIsTyping] = useState(false);
  const [isListOpened, setIsListOpened] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [fetchedList, setFetchedList] = useState<FetchedCompany[]>([]);
  const [hasArrowOpened, setHasArrowOpened] = useState(false);
  const [listNavigationIndex, setListNavigationIndex] = useState(0);
  const [hasLookUpBeenTriggered, setHasLookUpBeenTriggered] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<SelectedCompany>();
  const [isFetching, setIsFetching] = useState(false);

  const lookUpRef = useRef<HTMLButtonElement | null>(null);

  const autoComplete = type === 'password' ? 'new-password' : undefined;
  const hiddenClass = id === 'hidden' ? styles.hidden : '';
  const dynamicType = id === 'hidden' ? 'password' : type;
  const registered =
    typeof register !== 'undefined'
      ? {
          ...register(name, {
            required,
            disabled,
          }),
        }
      : null;

  const handleOnChange = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (value?.selected) {
      setInputValue('');
      onSelection({
        invalidText: searchAndSelectAgain,
        selected: false,
      });
      return;
    }
    const someValue = e.currentTarget.value;

    setIsTyping(true);
    setInputValue(someValue);
    onSelection({
      value: {
        name: someValue,
      },
    });
  };

  const toggleList = () => {
    setIsListOpened((prev) => !prev);
  };

  const closeList = () => {
    setIsListOpened(false);
  };

  const forceCloseList = () => {
    setIsListOpened(false);
    setIsTyping(false);
  };

  const listRef = useOutsideClick(() => forceCloseList());

  const handleSelectionClick = (
    e: MouseEvent<HTMLButtonElement>,
    selectedItem: any,
  ) => {
    e.preventDefault();

    onSelection({
      value: {
        city: selectedItem.addresses[0].city ?? '',
        houseNumber: selectedItem.addresses[0].houseNumber ?? '',
        name: selectedItem.name,
        street: selectedItem.addresses[0].street ?? '',
        zipCode: selectedItem.addresses[0].zipCode ?? '',
        supplierId: selectedItem.identifier.supplierId ?? '',
        providerName: selectedItem.identifier.providerName ?? '',
        supplierIdType: selectedItem.identifier.supplierIdType ?? '',
        vatNumber: selectedItem.identifier.vatNumber ?? '',
      },
      selected: true,
      invalidText: undefined,
    });
    setIsTyping(false);
    forceCloseList();
  };

  const navigateList = (keyPressed: string, event: any) => {
    if (keyPressed === 'ArrowUp') {
      setListNavigationIndex((prev) => {
        if (prev === 0) {
          return prev;
        } else {
          return prev - 1;
        }
      });
    } else if (keyPressed === 'ArrowDown') {
      setListNavigationIndex((prev) => {
        if (prev < fetchedList.length - 1) {
          return prev + 1;
        } else {
          return prev;
        }
      });
    } else if (keyPressed === 'Enter') {
      event.target.click();
    }
  };

  const handleInputKeyPress = (event: any) => {
    const keyPressed = event.code;

    if (keyPressed === 'Escape') {
      setIsTyping(false);
      closeList();
    } else if (keyPressed === 'ArrowUp') {
      toggleList();
    } else if (keyPressed === 'ArrowDown') {
      if (listRef.current !== undefined && isListOpened) {
        const listUl = listRef.current as HTMLUListElement;
        const firstListLi = listUl.childNodes[
          listNavigationIndex
        ] as HTMLLIElement;
        const listFirstButton = firstListLi.childNodes[0] as HTMLButtonElement;

        listFirstButton.focus();
        return;
      }
      if (hasLookUpBeenTriggered) {
        toggleList();
      }
    } else if (keyPressed === 'Enter') {
      event.preventDefault();
      if (lookUpRef.current !== undefined || lookUpRef.current !== null) {
        lookUpRef.current?.click();
      }
    } else {
      setIsTyping(true);
    }
  };

  const handleListKeyPress = (event: any) => {
    event.preventDefault();
    const keyPressed = event.code;

    navigateList(keyPressed, event);

    if (keyPressed === 'Escape') {
      setIsTyping(false);
      closeList();
    } else if (keyPressed === 'Tab') {
      forceCloseList();
    } else if (keyPressed === 'Enter') {
      forceCloseList();
    } else {
      setIsTyping(true);
    }
  };

  const handleBlurList = () => {
    setIsTyping(false);
  };

  const handleLookup = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (inputValue.length > 0) {
      setIsFetching(true);
      if (toggleDisabledAllFields) {
        toggleDisabledAllFields();
      }
      setHasLookUpBeenTriggered(true);

      const response = await APICall(inputValue);

      if (response.length > 0) {
        setFetchedList(response);
      } else {
        setFetchedList([]);
      }
      setIsFetching(false);
      toggleList();
      if (toggleDisabledAllFields) {
        toggleDisabledAllFields();
      }
    }
  };

  const handleSelectCompanyNotFound = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    let newValueCompanyNotFound = inputValue;

    if (!inputValue.includes(`(${companyNotFound})`)) {
      newValueCompanyNotFound = `${inputValue} (${companyNotFound})`;
    }

    onSelection({
      selected: true,
      invalidText: undefined,
      value: {
        name: newValueCompanyNotFound,
        city: '',
        houseNumber: '',
        street: '',
        supplierId: '',
        vatNumber: '',
        zipCode: '',
      },
    });

    forceCloseList();
  };

  const invalidateInput = () => {
    if (invalidText) {
      if (invalidText.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (value && value.value.name && value.value.name.length > 0) {
      setInputValue(value.value.name);
    }
  }, [value?.value.name]);

  useEffect(() => {
    if (listRef.current !== null && window !== undefined) {
      const current = listRef.current as HTMLUListElement;
      const childNodes = current.childNodes;
      const selectedNode = childNodes[listNavigationIndex];
      const selectedButton = selectedNode.childNodes[0] as ListItemButton;

      current.scrollIntoView({
        behavior: 'smooth',
      });
      selectedButton.focus();
    }
  }, [listRef, listNavigationIndex]);

  useEffect(() => {
    if (hasArrowOpened) {
      setIsListOpened(true);
    } else {
      forceCloseList();
    }
  }, [hasArrowOpened]);

  useEffect(() => {
    if (isTyping && selectedCompany !== undefined) {
      setInputValue('');
    }
  }, [selectedCompany, isTyping]);

  useEffect(() => {
    if (selectedCompany) {
      onSelection({
        value: {
          city: selectedCompany.addresses[0].city,
          houseNumber: selectedCompany.addresses[0].houseNumber,
          name: selectedCompany.name,
          street: selectedCompany.addresses[0].street,
          zipCode: selectedCompany.addresses[0].zipCode,
          supplierId: selectedCompany.identifier.supplierId,
          providerName: selectedCompany.identifier.providerName,
          supplierIdType: selectedCompany.identifier.supplierIdType,

          ...(selectedCompany.identifier.vatNumber
            ? { vatNumber: selectedCompany.identifier.vatNumber }
            : {}),
        },
        selected: true,
      });
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (value && value.invalidText === timeoutTranslation) {
      forceCloseList();
      onSelection({
        invalidText: timeoutTranslation,
      });
    }
  }, [value?.invalidText]);

  useEffect(() => {
    if (handleProgressIndicator && typeof invalid !== 'undefined') {
      handleProgressIndicator(invalid);
    }
  }, [invalid]);

  const canListOpen = isListOpened;

  const disabledSearchButtonStyle = isDisabled
    ? styles.disabledSearchButton
    : '';

  return (
    <div
      className={`${styles.inputContainer} ${hiddenClass}`}
      style={{
        ...(margin ? { marginBottom: `${margin}rem` } : {}),
      }}
    >
      <div className={styles.labelLinkContainer}>{sideButton}</div>
      <div className={styles.inputIconContainer}>
        <TextInput
          className={`${styles.input} ${invalid ? styles.inputInvalid : ''}`}
          {...registered}
          id={id}
          {...(typeof autoComplete !== 'undefined'
            ? { autoComplete: autoComplete }
            : {})}
          type={dynamicType}
          labelText={label}
          size="lg"
          value={value?.value.name}
          invalid={invalidateInput()}
          invalidText={invalidText}
          disabled={isDisabled}
          onChange={handleOnChange}
          onKeyDown={handleInputKeyPress}
          data-testid={dataTestId}
          ref={inputRef}
          style={{
            ...(tabOrder ? { order: tabOrder } : {}),
            ...(padding ? { paddingLeft: `${padding}rem` } : {}),
          }}
          {...rest}
        />
        <button
          className={`${styles.callApiTriggerButton} ${invalid ? styles.callApiTriggerButtonInvalid : ''} ${disabledSearchButtonStyle}`}
          onClick={handleLookup}
          type="button"
          ref={lookUpRef}
          data-testid={sideButtonDataTestId}
          disabled={isDisabled}
          style={{
            ...(invalid ? { right: '3rem' } : {}),
          }}
          tabIndex={rest.tabIndex && rest.tabIndex}
        >
          {search}
        </button>
      </div>
      {canListOpen && (
        <ul
          className={styles.list}
          ref={listRef}
          onBlur={handleBlurList}
          onKeyDown={handleListKeyPress}
        >
          {fetchedList.map((option, index: number) => {
            const isLastItem = fetchedList.length - 1 === index;
            const isSelected = index === listNavigationIndex;

            const filteredIdOption = {
              name: option.name,
              addresses: option.addresses,
              identifier: option.identifiers,
            };
            return (
              <li
                className={`${styles.listItem} ${!isLastItem ? styles.listItemAfter : ''}`}
                key={index}
              >
                <button
                  onClick={(e) => handleSelectionClick(e, filteredIdOption)}
                  className={`${styles.listItemButton} ${isSelected ? styles.listItemButtonSelected : ''}`}
                >
                  <div className={styles.listItemButtonContentContainer}>
                    <p className={styles.listItemButtonContentPCompanyName}>
                      {filteredIdOption.name}
                    </p>
                    <p className={styles.listItemButtonContentP}>
                      {`${companyAddress}: ${filteredIdOption.addresses[0].address}`}
                    </p>
                    <p className={styles.listItemButtonContentP}>
                      {option?.identifiers.vatNumber &&
                        `${vatNumber}: ${option.identifiers.vatNumber}`}
                    </p>
                  </div>
                </button>
              </li>
            );
          })}
          <li className={styles.listItem}>
            <button
              onClick={handleSelectCompanyNotFound}
              className={styles.listItemButton}
              data-testid="companynotfound"
            >
              {companyNotFound}
            </button>
          </li>
        </ul>
      )}
      {isFetching && (
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <div className={styles.listItemLoading}>
              {isFetching && (
                <CustomColorLoadSpinner
                  props={{
                    color: '#1dd3b0',
                    size: 24,
                  }}
                />
              )}
            </div>
          </li>
        </ul>
      )}
    </div>
  );
};

export default CompanyNameInput;
