import { ChevronDown, WarningFilled } from '@carbon/icons-react';
import React, { ChangeEvent, FC, MouseEvent, useEffect, useState } from 'react';

import { useOutsideClick, useTranslations } from '@/hooks';
import styles from './index.css';
import {
  ListItemButton,
  ListNavigationDirection,
  Option,
  SearchableDropdownProps,
} from './types';

const SearchableDropdown: FC<SearchableDropdownProps> = ({
  options,
  label,
  id,
  placeholder,
  invalid,
  invalidText,
  handler,
  lang,
  value,
  isDisabled,
  tabOrder,
  tabIndex,
  margin,
  resetFields,
  dataTestId,
  dropdownTestId,
}) => {
  const [isTyping, setIsTyping] = useState(false);
  const [isListOpened, setIsListOpened] = useState(false);
  const [searchableItems, setSearchableItems] = useState(options);
  const [searchText, setSearchText] = useState('');
  const [hasArrowOpened, setHasArrowOpened] = useState(false);
  const [listNavigationIndex, setListNavigationIndex] = useState(0);
  const [listNavigationDirection, setListNavigationDirection] =
    useState<ListNavigationDirection>('');

  const {
    components: {
      forms: {
        countries: { notFound },
      },
    },
  } = useTranslations(lang);

  const hasInputError = invalid ? styles.inputError : '';
  const disabledLabelStyle = isDisabled ? styles.disabledLabel : '';
  const disabledStyle = isDisabled ? styles.disabled : '';
  const inputIconOpenedStyle = isListOpened ? styles.inputIconOpened : '';
  const disabledArrowStyle = isDisabled ? styles.disabledArrow : '';

  const toggleList = () => {
    setIsListOpened((prev) => !prev);
  };

  const closeList = () => {
    setIsListOpened(false);
  };

  const listRef = useOutsideClick(toggleList);

  const stopTyping = () => {
    setIsTyping(false);
  };

  const forceCloseList = () => {
    setIsListOpened(false);
    setIsTyping(false);
  };

  const handleFilter = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setIsTyping(true);
    const value = e.currentTarget.value;
    const aux = options;

    const filtered = aux.filter((item) => {
      const removedSoftHyphen = item.label
        .split('')
        .filter((char) => char.charCodeAt(0) !== 173)
        .join('');

      return removedSoftHyphen.toLowerCase().includes(value.toLowerCase());
    });

    setSearchText(value);
    setSearchableItems(filtered);
  };

  const handleSelectFromList = (selectedItem: Option) => {
    setSearchText(selectedItem.label);
    handler({
      value: {
        value: selectedItem.value,
        label: selectedItem.label,
      },
    });
  };

  const handleSelectionClick = (
    e: MouseEvent<HTMLButtonElement>,
    selectedItem: Option,
  ) => {
    e.preventDefault();
    handleSelectFromList(selectedItem);
    stopTyping();
    toggleList();

    if (resetFields) {
      resetFields();
    }
  };

  const navigateList = (keyPressed: string, event: any) => {
    if (keyPressed === 'ArrowUp') {
      setListNavigationIndex((prev) => {
        setListNavigationDirection('up');
        if (prev === 0) {
          return prev;
        } else {
          return prev - 1;
        }
      });
    } else if (keyPressed === 'ArrowDown') {
      setListNavigationIndex((prev) => {
        setListNavigationDirection('down');
        if (prev < searchableItems.length - 1) {
          return prev + 1;
        } else {
          return prev;
        }
      });
    } else if (keyPressed === 'Enter') {
      event.target.click();
    }
  };

  useEffect(() => {
    if (listRef.current !== null && window !== undefined) {
      const current = listRef.current as HTMLUListElement;
      const childNodes = current.childNodes;
      const selectedNode = childNodes[listNavigationIndex];
      const selectedButton = selectedNode.childNodes[0] as ListItemButton;

      current.scrollIntoView({
        behavior: 'smooth',
      });
      selectedButton.focus();
    }
  }, [listRef, listNavigationIndex]);

  const handleInputKeyPress = (event: any) => {
    const keyPressed = event.code;

    if (keyPressed === 'Escape') {
      setIsTyping(false);
      closeList();
    } else if (keyPressed === 'ArrowUp') {
      toggleList();
    } else if (keyPressed === 'ArrowDown') {
      if (listRef.current !== undefined && isListOpened) {
        const listUl = listRef.current as HTMLUListElement;
        const firstListLi = listUl.childNodes[
          listNavigationIndex
        ] as HTMLLIElement;
        const listFirstButton = firstListLi.childNodes[0] as HTMLButtonElement;

        listFirstButton.focus();
        return;
      }
      toggleList();
    } else {
      setIsTyping(true);
    }
  };

  const handleListKeyPress = (event: any) => {
    event.preventDefault();
    const keyPressed = event.code;

    navigateList(keyPressed, event);

    if (keyPressed === 'Escape') {
      setIsTyping(false);
      closeList();
    } else if (keyPressed === 'Tab') {
      forceCloseList();
    } else if (keyPressed === 'Enter') {
      forceCloseList();
    } else {
      setIsTyping(true);
    }
  };

  const handleBlurList = () => {
    setIsTyping(false);
  };

  const handleClickArrow = () => {
    if (!isDisabled) {
      setHasArrowOpened((prev) => !prev);
    }
  };

  const preselectValue = () => {
    if (value?.value) {
      const preselected = options.filter((option) =>
        option.value.includes(value.value.value),
      );
      handleSelectFromList(preselected[0]);
    }
  };

  useEffect(() => {
    if (value?.value?.value) {
      preselectValue();
    }
  }, [value?.value?.value]);

  const canListOpen = isListOpened || isTyping;

  useEffect(() => {
    if (hasArrowOpened) {
      setIsListOpened(true);
    } else {
      forceCloseList();
    }
  }, [hasArrowOpened]);

  return (
    <div
      className={styles.container}
      style={{
        ...(margin ? { marginBottom: `${margin}rem` } : {}),
      }}
    >
      {label && (
        <label className={`${styles.label} ${disabledLabelStyle}`} htmlFor={id}>
          {label}
        </label>
      )}
      <div className={styles.inputErrorMessageContainer}>
        <div className={styles.inputIconContainer}>
          <input
            type="text"
            className={`${styles.input} ${hasInputError} ${disabledStyle}`}
            id={id}
            placeholder={placeholder}
            onClick={toggleList}
            onChange={handleFilter}
            value={searchText}
            autoComplete="off"
            disabled={isDisabled}
            onBlur={stopTyping}
            onKeyDown={handleInputKeyPress}
            tabIndex={tabIndex}
            style={{
              ...(tabOrder ? { order: tabOrder } : {}),
            }}
            data-testid={dataTestId}
          />
          <div className={styles.iconsContainer} tabIndex={-1}>
            {invalid && (
              <WarningFilled
                size={16}
                className={styles.errorIcon}
                tabIndex={-1}
              />
            )}
            <ChevronDown
              size={16}
              className={`${styles.inputIcon} ${inputIconOpenedStyle} ${disabledArrowStyle}`}
              onClick={handleClickArrow}
              tabIndex={-1}
            />
          </div>
        </div>
        <div className={styles.errorMessage}>{invalidText}</div>
      </div>
      {canListOpen && (
        <ul
          className={styles.list}
          ref={listRef}
          onBlur={handleBlurList}
          onKeyDown={handleListKeyPress}
        >
          {searchableItems.length === 0 && (
            <p className={styles.notFound}>{notFound}</p>
          )}
          {searchableItems.map((option, index) => {
            const isLastItem = searchableItems.length - 1 === index;
            const isSelected = index === listNavigationIndex;
            return (
              <li className={styles.listItem} key={index}>
                <button
                  onClick={(e) => handleSelectionClick(e, option)}
                  className={`${styles.listItemButton} ${!isLastItem ? styles.listItemButtonAfter : ''} ${isSelected ? styles.listItemButtonSelected : ''}`}
                  data-testid={`${dropdownTestId}-option-${index}`}
                >
                  {`${option.label}`}
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default SearchableDropdown;
