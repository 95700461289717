import { DEBITOS_MARKETPLACE } from '@/constants';
import { login as loginStore, user } from '@/store';
import {
  getCookie,
  getLocalStorage,
  removeLocalStorage,
  setCookie,
  setSecureCookie,
  toQuery,
} from '@/utils';
import { useStore } from '@nanostores/react';
import { FormEvent, useEffect, useState } from 'react';
import useTranslations from '../useTranslations';
import { defaultFieldsValues } from './data';
import { Errors, FieldKey, Fields, UseLoginProps } from './types';
import zodLogin from './zod';

const useLogin = ({ lang, currentStep: cStep = 0 }: UseLoginProps) => {
  const {
    components: {
      forms: { invalidCredentials },
    },
  } = useTranslations(lang);
  const login = useStore(loginStore.$login);
  const { LoginEmailStep, LoginPasswordStep } = zodLogin(lang);

  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState<Fields>(defaultFieldsValues);
  const [currentStep, setCurrentStep] = useState(cStep ?? 0);
  const [errors, setErrors] = useState<Errors>([]);
  const [isHiddenFieldFilled, setIsHiddenFieldFilled] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState<string | null>(null);

  const setFieldsValues = ({ email, hidden, password, rememberMe }: Fields) => {
    const localFields = fields;

    const localEmail = email ?? localFields.email;
    const localHidden = hidden ?? localFields.hidden;
    const localRememberMe = rememberMe ?? localFields.rememberMe;
    const localPassword = password ?? localFields.password;

    setFields({
      email: localEmail,
      hidden: localHidden,
      rememberMe: localRememberMe,
      password: localPassword,
    });
  };

  const handleNextStep = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const parseErrors = () => {
    switch (currentStep) {
      case 0: {
        return LoginEmailStep.safeParse(fields);
      }
      case 1: {
        return LoginPasswordStep.safeParse(fields);
      }
      default: {
        return LoginEmailStep.safeParse(fields);
      }
    }
  };

  const handleSetErrors = (errorObjects: any) => {
    setFieldsValues({
      ...errorObjects,
    });
  };

  const handleErrors = () => {
    const parsed = parseErrors();

    if (parsed.success) return true;

    const errors = parsed.error.issues;
    const errorObjects = {} as any;

    errors.forEach((error: any) => {
      const path = error.path[0] as FieldKey;
      const message = error.message;
      const invalidTextObject = {
        invalidText: message,
      };

      errorObjects[path] = invalidTextObject;
    });

    handleSetErrors(errorObjects);

    return false;
  };

  const handleInput = (e: any, fieldKey: FieldKey, errorMessage?: string) => {
    const prevValue = fields[fieldKey]?.value ?? '';
    const value = {
      value:
        typeof e.currentTarget?.value !== 'undefined'
          ? e.currentTarget.value
          : prevValue,
      invalidText: errorMessage,
    };
    const field = {
      [fieldKey]: value,
    };
    setFieldsValues(field);
  };

  const handleCheckbox = (key: string) => {
    // @ts-ignore
    const prev = fields[key];
    setFieldsValues({
      [key]: {
        value: !prev.value,
      },
    });
  };

  const handleStep = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errorHandling = handleErrors();

    if (errorHandling) {
      if (currentStep < 1) {
        handleNextStep();
      }
      return true;
    }
    return false;
  };

  const submitEmail = (e: FormEvent<HTMLFormElement>) => {
    if (fields.hidden && fields.hidden?.value?.length > 0) {
      setIsHiddenFieldFilled(true);
    }
    setErrors([]);
    handleStep(e);
  };

  const submitPassword = async (e: FormEvent<HTMLFormElement>) => {
    setErrors([]);

    if (isHiddenFieldFilled) return;

    const stepHandled = handleStep(e);
    if (stepHandled) {
      setIsLoading(true);

      try {
        const token = await user.loginInUser({
          email: fields.email?.value as string,
          password: fields.password?.value as string,
        });

        if (fields.rememberMe?.value) {
          setCookie('rememberedEmail', fields.email?.value as string, 30);
        }

        if (token?.access_token && token?.marketplace_token) {
          setSecureCookie('access_token', token?.access_token, 1);
          const redirectUrl = getLocalStorage('redirect');
          let marketUrl = `${DEBITOS_MARKETPLACE}/Signin/token/${token.marketplace_token}`;

          if (redirectUrl) {
            removeLocalStorage('redirect');
            marketUrl += toQuery({ redirect: redirectUrl });
          }
          window.location.href = marketUrl;
        } else {
          setIsLoading(false);
          setErrors([invalidCredentials]);
        }
      } catch (error) {
        setIsLoading(false);
        setErrors([invalidCredentials]);
      }
    }
  };

  const submit2FA = (e: FormEvent<HTMLFormElement>) => {
    handleStep(e);
  };

  const submitDoYouHaveAnDebitosAccountEmail = ({ email }: any) => {};

  const rememberedEmail = getCookie('rememberedEmail');

  useEffect(() => {
    if (rememberedEmail) {
      setFieldsValues({
        email: {
          value: rememberedEmail,
        },
        rememberMe: {
          value: true,
        },
      });
    }
  }, [rememberedEmail]);

  return {
    fields,
    currentStep,
    errors,
    isLoading,
    login,
    loginStore,
    confirmEmail,
    setConfirmEmail,
    setFieldsValues,
    setErrors,
    handleInput,
    handleCheckbox,
    submitEmail,
    submitPassword,
    submit2FA,
    handlePreviousStep,
    submitDoYouHaveAnDebitosAccountEmail,
  };
};

export default useLogin;
